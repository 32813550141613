.otp-inputs {
    display: flex;
    justify-content: center;
    gap: 10px;
    /* Use gap for consistent spacing */
    margin-bottom: 20px;
    /* Add some space below the input fields */
}

.otp-input {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 1.2rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-weight: bolder;
    outline: none;
    transition: all 0.3s ease;
    /* Smooth transition for hover/focus effects */
}

.otp-input:focus {
    border-color: #116530;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}

.otp-input:hover {
    border-color: #116530;
}

.otp-input::placeholder {
    color: #ccc;
    font-size: 1.2rem;
}

.mail-email {
    font-weight: bold;
    color: #007bff;
}

.otp-container {
    max-width: 30rem;
    margin: 0 auto;
    padding: 20px;
    /* background-color: #f8f9fa; */
    border-radius: 10px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

button.ver {
    width: 100%;
    padding: 10px;
    font-size: 1.2rem;
    border-radius: 8px;
}

/* Media Queries */
@media (max-width: 767px) {
    .mail-image {
        display: none;
        /* Hide the image on small screens */
    }

    .otp-container {
        padding: 10px;
        /* Reduce padding on small screens */
    }

    .otp-input {
        width: 40px;
        /* Adjust input size on small screens */
        height: 40px;
        font-size: 1.2rem;
    }
}