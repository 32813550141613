.aboutUs-top-part{
    background: #0D8548;
    border-radius: 0 0 70px 70px;
}
.about-section-a{
    margin: 0 auto ;
}
.about-section-b{
    margin: 0 auto;
}
.about-section-a h2{
    font-size: 57px;
    color: #fff;
    text-align: center;
    font-weight: 700;
    margin-bottom: 1rem;
}
.about-section-b h2{
    font-size: 48px;
    /* color: #fff; */
    text-align: center;
    font-weight: 700;
    margin-bottom: 1rem;
}
.about-section-a p{
    text-align: center;
    font-size: 21px;
    line-height: 2.1rem;
    color: #fff;
    font-weight: 300;
}
.about-section-b p{
    /* text-align: right; */
    font-size: 21px;
    line-height: 2.1rem;
    /* color: #fff; */
    font-weight: 300;
}

@media (min-width:768px){
    .about-section-a{
        width: 680px;
        /* text-align: center; */
    }
}
@media (max-width:768px){
    .about-section-a h2{
        font-size: 42px;        
     }
    .about-section-b h2{
        font-size: 38px;        
     }
     .about-section-a p, .about-section-b p{
         text-align: center;
         font-size: 18px;
     }
}
@media (max-width:992px){
    .about-img-section{
        display: none;
    }
    .about-section-a, .about-section-b{
        text-align: center;
    }
}