/* PaystackButtonWrapper.css */
.centered-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 100vh; /* Adjust height as needed for your layout */
}

.paystack-button-wrapper button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
