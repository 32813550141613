.btn-get-started{
    background: #27AE60;
}
.footer{
    color: #fff;
    background: #116530;
    /* z-index: 10; */
}
.footer .textVC{
    display: flex;
    align-items: center;
    text-align: right   ;
}
.brand a{
    font-size: 36px;
    color: #fff;
    text-decoration: none;
}
.footer .newsletter{
    font-size: 18px;
}
.footer .btn-newsletter{
    background: #27AE60;
    display: inline-flex;
    align-items: center;
    height: 45px;
}
.footer .newsletter-span{
    height: 45px;
    width: fit-content;
    border-bottom: 1px solid #aaa;
}
.footer .newsletter-span input{
    height: 45px;
    border: none;
    width: 170px;
    background: transparent;
}
.footer ul li {
    list-style: none;
}
.footer ul li a, .footer .social-icons a{
    text-decoration: none;
    color: #fff;
}
.footer ul .weight-low{
    font-weight: 300;
    line-height: 2.7rem;
}
.footer .social-icons{
    display: flex;
    gap: 40px;
}