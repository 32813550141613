.FProfile img{
    display: flex;
    margin: 0 auto;
    border-radius: 50%;
    margin-top: 10px;    
    margin-bottom: 20px;    
    border: 2px solid #c3d8cb;
}
.FProfile .input{
    margin-bottom: 10px;    
}
.displayNone{
    display: none;
}