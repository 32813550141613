/* note that this css file is used both for the 
buyer and the farmer sign in */

.signIn-page .row .leftSide {
  background-color: #116530;
  height: 100vh;
  color: #fff;
}
.leftSide .left-text {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  /* width: 350px; */
  margin: 0 auto;
  margin-top: 35%;
  justify-content: center;
}
.leftSide .left-text p {
  font-size: 18px;
  font-weight: 400;
}
.leftSide .left-text h2 {
  font-size: 38px;
  font-weight: 500;
}
.right-signIn-page .row {
  margin: 0 auto;
}
.right-signIn-page .panel-body .cl-h2 {
  text-align: center;
  font-weight: 500;
}
.hld-btns-with {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.or-sign-with .hr-w {
  width: 15px;
  color: #000;
}
.or-sign-with {
  color: #000;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.btn-sign-w {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  gap: 10px;
  font-weight: 600;
}
.forgot a {
  text-decoration: none;
  color: #000;
  font-weight: 400;
}
.not-user {
  display: block;
  text-align: center;
}
.not-user a {
  text-decoration: none;
  color: #116530;
  font-weight: 600;
}

.right-signIn-page {
  position: relative;
}
.error-msg,
.success-msg {
  position: absolute;
  top: 0;
  left: 50%;
  border-radius: 0 0 7px 7px;
  transform: translateX(-50%);
  z-index: 2;
  background: red;
  width: fit-content;
  font-size: 18px;
  font-weight: 500;
  color: white;
  padding: 10px;
  opacity: 0;
  transition: 0.35s;
}
.success-msg {
  background: #378805;
}
.error-msg.open,
.success-msg.open {
  opacity: 1;
}

.profile-img {
  width: 100px;
  height: 100px;
  color: #116530;
  border: 7px solid #116530;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .signIn-page .row .leftSide {
    display: none;
  }
}
@media (max-height: 525px) {
  .signIn-page .row .leftSide {
    height: 200vh;
  }
}
@media (min-height: 525px) {
  .right-signIn-page .panel-body {
    position: relative;
    top: 50vh;
    transform: translateY(-50%);
  }
}
