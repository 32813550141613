.header-bg{
    background: url("../images/Vector.png") top left no-repeat;
    background-size: cover;
    height: 120px;
    z-index: 1;
}
.navLink.activePage{
    color: #D5D905 !important;
}
.cart-home-header{
    color: #fff !important;
    font-size: 1rem;
    border: 2px solid #fff;
    padding: 10px;
    border-radius: 50%;
}
.nav-right-items{
    display: flex;
    align-items: center;
    /* flex-direction: row; */
    gap: 15px;
}
.rel{
    position: relative;
}
.counter{
    position: absolute;
    left: -10px;
    top: 7px;
    color: #116530;
    background-color: #D5D905;
    padding: 2px 4px;
    border-radius: 50%;
}
.profileBar{
    width: 40px;
    border-radius: 50%;
    /* height: 40px; */
}
.navLink{
    color: #fff;
    position: relative;
    font-weight: 500;
    font-size: 18px;
    text-decoration: none;
}
.navLink:hover{
    color: #D5D905;
    transition: color 0.3 ease;
}
.navLinksHome a:focus{
    color: #D5D905 !important;
}
/* .navLink:active, .navLink:focus{
} */
@media (min-device-width:992px){
    .navLink:hover::after{
        content:"";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 7px;
        border-radius: 50%;
        height: 7px;
        background-color: #D5D905;
        transition: all 0.3 ease;
    }
    .header-bg{
        background: url("../images/VectorBig.png") top left no-repeat;
        background-size: cover;
        height: 160px;
    }
    .cart-home-header{
        color: #fff;
        font-size: 1.3rem;
        border: 2px solid #fff;
        padding: 10px;
        border-radius: 50%;
    }
    .counter{
        position: absolute;
        left: 0;
        top: 7px;
        color: #116530;
        background-color: #D5D905;
        padding: 2px 5px;
        border-radius: 50%;
    }
    .show-dropdown-offset{
        display: none;
    }
}

@media (min-device-width:1665px){
    .header-bg{
        background: url("../images/VectorBig.png") top left no-repeat;
        background-size: cover;
        height: 300px;
    }
}
@media (max-width:992px){
    .show-dropdown-nav{
        display: none;
    }
    .show-dropdown-offset{
        display: block;
    }
}