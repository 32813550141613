.cont_sideBar {
  display: flex;
  height: 100vh;
  width: 100%;
}

main {
  width: 100%;
  overflow-y: auto;
  margin-bottom: 1rem;
}

.cont_sideBar .sidebar {
  position: sticky;
  top: 0;
  bottom: 0;
  width: 250px;
  background: #116530;
  color: #fff;
  transition: width 0.5s, visibility 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cont_sideBar .sidebar.shrink {
  width: 50px;
}

.cont_sideBar .sidebar.collapsed {
  width: 20px;
}

.cont_sideBar .top_section {
  display: flex;
  align-items: center;
  padding: 15px 10px 0px;
}

.cont_sideBar .top_section .logo {
  font-size: 30px;
  margin-bottom: 0;
  margin-left: 25px;
  display: flex;
  align-items: center;
  transition: visibility 0.5s;
}

.cont_sideBar .top_section .logo.hidden {
  visibility: hidden;
}

.cont_sideBar .top_section .bars {
  display: flex;
  font-size: 25px;
  cursor: pointer;
}

.cont_sideBar .menu_items_container {
  height: calc(100vh - 160px);
  overflow-y: auto;
}

.cont_sideBar .link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.3s;
  text-decoration: none;
  align-items: center;
}

.cont_sideBar .link:hover {
  background-color: #d5d905;
  color: #116530;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cont_sideBar .link .icon,
.cont_sideBar .link .link_text {
  font-size: 20px;
}

.cont_sideBar .link .link_text {
  display: inline-block;
  transition: visibility 0.5s;
}

.cont_sideBar .sidebar.shrink .link .link_text,
.cont_sideBar .sidebar.collapsed .link .link_text {
  display: none;
}

.cont_sideBar .active {
  background-color: #d5d905;
  color: #116530;
}

.cont_sideBar .link .icon {
  color: #fff;
}

.cont_sideBar .logout_section {
  padding: 15px;
}

.cont_sideBar .logout_section .link {
  justify-content: center;
}

/* @media (max-width: 768px) {
  .cont_sideBar .sidebar {
    width: 20px;
    visibility: visible;
  }

  .cont_sideBar .sidebar .top_section .bars {
    font-size: 20px;
  }

  .cont_sideBar .sidebar .top_section .logo,
  .cont_sideBar .sidebar .link .link_text {
    visibility: hidden;
  }
} */