
.green-select option {
    background-color: #2ecc71; /* Green background */
    color: #fff; /* White text */
}

.green-select option:hover {
    background-color: #27ae60; /* Darker green background on hover */
}


.green-select::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

/* Track */
.green-select::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

/* Handle */
.green-select::-webkit-scrollbar-thumb {
    background: #116530; 
    border-radius: 10px; 
}

/* Handle on hover */
.green-select::-webkit-scrollbar-thumb:hover {
    background: #0f5123;
}

.green-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    font-size: 16px;
    appearance: none; /* Remove default appearance */
    -webkit-appearance: none; /* Remove default appearance for webkit browsers */
    -moz-appearance: none; /* Remove default appearance for Firefox */
}

/* Apply custom background color and text color on hover */
.green-select option:hover {
    background-color: #116530; /* Shade of green */
    color: #fff; /* White text color */
}

.green-select option:checked {
    background-color: #116530; /* Shade of green */
    color: #fff; /* White text color */
}
