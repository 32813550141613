.homepage {
    position: relative;
}

.top-holder {
    position: relative;
}

.homepage section {
    margin: 100px 0;
}

.sec-header {
    font-size: 32px;
}

.sec-para {
    font-size: 19px;
    color: #313030;
}

.textss {
    place-items: center;
    margin: auto 0;
}

.about {
    text-align: right;
}

.btn-hp {
    border-radius: 20px;
    background-color: #116530
}

.btn-hpAbt {
    border-radius: 20px;
    background-color: #fff;
    color: #116530;
    display: flex;
    margin: 0 auto;
}

.sec-header .colG {
    color: #116530
}

.topicWL {
    color: #116530;
}

.topicWL::after,
.topicWL::before {
    content: '';
    display: inline-block;
    width: 70px;
    height: 3.5px;
    border-radius: 2px;
    margin: 0 5px 7px 5px;
    vertical-align: auto;
    background-color: #116530;
}

.catIc {
    border: 5px double #116530;
    padding: 15px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.catImg-holder {
    text-align: center;
    margin-bottom: 50px;
}

.myCarrot {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.myLeaves {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.wYam {
    position: relative;
}

.aYam {
    position: absolute;
    bottom: -100px;
    right: 55px;
    width: 80px;
    z-index: -1;
}

.seeds {
    position: absolute;
    bottom: -350px;
    right: 0;
    z-index: -1;
}

.adjust-height-on-size {
    position: relative;
    /* height: 900px; */
    /* height: 590px; */
    margin-bottom: 150px;
    /* margin-bottom: -200px; */
}




@media (max-width:992px) {
    .adjust-height-on-size {
        /* margin-bottom: 50px;
    height: auto; */
    }
    .textss {
        text-align: left;
    }
    .textss h2{
        font-size: 30px;
    }
    /* .sec-header {
        font-size: 32px;
    }     */
    .homepage .about{
        text-align: center;
    }
    .topSection .resImg,
    .aYam,
    .seeds {
        display: none;
    }

    .myCarrot,
    .myLeaves {
        display: none;
    }
}

@media (max-width:767px) {
    .category-section {
        display: none;
    }
}

@media (min-width:1801px) {

    .myCarrot,
    .myLeaves,
    .seeds {
        display: none;
    }
}