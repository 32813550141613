.shop_mapped_items {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

.shop_form {
  width: 260px;
  margin-left: auto;
}

.shop_form input[type="search"] {
  border: 1.5px solid #116530;
  padding-left: 40px;
  border-radius: 45px;
}

.shop_form input[type="search"]:focus {
  border: none;
  outline: none;
  /* border-color: #116530; */
  box-shadow: 0 0 3px 0.2rem #116530;
}

.shop_form input[type="search"]:focus .shop_search_icon {
  transform: scale(1);
}

.shop_search_section {
  position: relative;
}

.shop_search_section .shop_search_icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
  top: 0;
  left: 0.25px;
  padding: 4px;
  width: 35.1px;
  height: 100%;
  color: #116530;
  border-radius: 50%;
  background: #e2fc44;
  transform: scale(0.9);
  animation: shrink 5s infinite linear;
}

.search_icon {
  padding: auto;
}

@media (max-width: 809px) {
  .shop_form {
    margin: 0 auto;
  }

  .shop-topSection {
    text-align: center;
  }
}

@keyframes shrink {

  /* 0%{
        font-size: 24px;
    }
    25%{
        font-size: 23px;
    }
    40%{
        font-size: 22px;
    }
    50%{
        font-size: 23px;
    }
    75%{
        font-size: 24px;
    }
    100%{
        font-size: 25px;
    } */
  0% {
    transform: scale(0.9);
  }

  25% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(0.9);
  }
}