/* Import Font Awesome for bank icons */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

/* General Modal Styles */
.modal-overlay {
    background-color: rgba(0, 0, 0, 0.78);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.modal-content {
    position: relative;
    width: 600px;
    max-width: 90%;
    max-height: 90vh;
    padding: 20px;
    border-radius: 10px;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    outline: none;
    overflow-y: auto;
    /* Enable scrolling */
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #116530;
}

.modal-body {
    display: flex;
    flex-direction: column;
}

/* Profile Section */
.profile-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background-color: rgb(172, 198, 183);
    padding: 1rem;
    border-top-left-radius: 2rem;
}

.avatar-image {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    object-fit: cover;
    margin-right: 20px;
}

.user-details {
    display: flex;
    flex-direction: column;
}

.user-details p {
    margin: 5px 0;
}

.user-details strong {
    color: #116530;
}

/* Account Info */
.account-info {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #f9f9f9;
}

.account-info p {
    margin: 10px 0;
    font-size: 16px;
    color: #333;
}

.account-info strong {
    color: #116530;
}

.bank-icon {
    font-size: 24px;
    color: #116530;
    margin-right: 10px;
    vertical-align: middle;
}

/* Flexbox for aligning items */
.account-info .account-details {
    display: flex;
    align-items: center;
}

.account-info .account-details img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.account-info .account-number {
    font-weight: bold;
}

/* Purchased Products */
.purchased-products {
    margin-top: 20px;
}

.purchased-products h2 {
    margin-bottom: 10px;
}

.cart {
    margin-bottom: 20px;
}

.cart-items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.cart-item {
    display: flex;
    flex-direction: column;
    width: 240px;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    background: #f9f9f9;
}

.cart-item-image img {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.cart-item-details {
    margin-top: 10px;
}

/* Campaigns Info */
.campaigns-info {
    margin-top: 20px;
}

.campaigns-info h2 {
    margin-bottom: 10px;
}

.product-campaign {
    margin-bottom: 20px;
}

.product-campaign h3 {
    margin-bottom: 10px;
}

.campaign-details {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    background: #f9f9f9;
}

.campaign-image img {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.campaign-info {
    margin-top: 10px;
}

.campaign-info h4 {
    margin-bottom: 10px;
}

.contributions {
    margin-top: 10px;
}

.contribution-details {
    margin-bottom: 10px;
}

.contribution-details p {
    margin: 5px 0;
}