.createCat_Header{
    background: #116530;
    text-align: center;
    margin: 0 auto;
}
.createCat_form{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}
.createCategory{
    margin: 0 auto;
}