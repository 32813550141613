
.contact-form .form-group div input, .contact-form .form-group div textarea{
    background: #def1e6;
}
.contact-left-side p{
    line-height: 1.7rem;
    font-size: 18px;
    /* color: #aaa; */
    color: #000;
    margin-bottom: 10px;
}
.contact-left-side{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.contact-left-side span{
    display: flex;
    gap: 10px;
    align-items: center;
}
.contact-icon{
    font-size:25px;
    color: #116530;
    border:3px solid #116530;
    border-radius: 50%;
    height: fit-content;
    width: auto;
    padding: 5px;
}
.contact .textarea-section{
    display: flex;
    margin: 0 auto;
}
/* .contact .textarea-section .textarea-holder .textarea{
    box-shadow: 0 0 5px #000;
    margin: 0 auto;
} */
.contact .textarea-section .btn-send{
    background: #27AE60;
    display: flex;
 
    margin-left: auto;
}
.col-md-9{
    background: #fff;
    padding: 3%;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
.contact-form label{
    font-weight:600;
}
.contact-form button{
    background: #116530;
    color: #fff;
    font-weight: 600;
    width: fit-content;
}
.contact-form button:focus{
    box-shadow:none;
}

@media (max-width:992px){
    /* .contact-left-side{
        text-align: center;
    } */
.contact-left-side span{
    flex-direction: column;
    margin-top: 20px;
}
.contact-illustration{
    display: none;
}
.contact-row{
    /* why not working */
    grid-auto-flow: dense;
}
}