@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* General styling for the receipt */
.receipt-font {
    font-family: 'Inter', sans-serif;
    color: #333;
}

.receipt-for {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
}

.client-info {
    margin-bottom: 1rem;
}

.client-info strong {
    font-size: 1.2rem;
    font-weight: 600;
}

.client-info h6 {
    font-size: 1.1rem;
    font-weight: 400;
}

.invoice-contain {
    width: 100%;
    background-color: #F2F5F9;
    padding: 2rem;
    border-radius: 15px;
}

.invoice-no h4 {
    font-size: 1rem;
    color: #60737D;
    font-weight: 400;
}

.invoice-no span {
    font-size: 1.4rem;
    color: #121722;
    font-weight: 700;
}

/* Responsive styling */
@media (max-width: 768px) {
    .invoice-contain {
        padding: 1rem;
    }

    .table {
        font-size: 0.9rem;
    }

    .btn {
        width: 100%;
        margin-bottom: 0.5rem;
    }
}

/* Print specific styles */
@media print {
    .receipt-font {
        margin: 0;
        padding: 0;
        font-size: 12px;
    }

    .container {
        width: 100%;
        margin: 0;
    }

    .invoice-contain {
        padding: 1rem;
        border: 1px solid #ddd;
        border-radius: 8px;
    }

    .total {
        padding: 1rem;
        background-color: #f2f5f9;
    }

    .img-responsive {
        max-width: 100%;
        height: auto;
    }

    footer {
        margin-top: 1rem;
        font-size: 12px;
    }

    .btn {
        display: none;
    }
}