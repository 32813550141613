
.btn-buyer,
.btn-farmer {
    background-color: #116530;
    padding: 10px 20px;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease;
}
.btn-buyer:hover,
.btn-farmer:hover {
    background-color: #0e4a27;
}
.buyer-registration h3,
.farmer-registration h3 {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 600;
    margin: 35px 0;
    color: #333;
}
.input-div {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.input-div input {
    border: 2px solid #ababab;
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}
.input-div input:focus {
    border-color: #116530;
    outline: none;
}
.or {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #aaa;
    margin: 2rem 0;
}
.hr-width{
    width: 10em;
} 
.btn-sign-with{
    width: 300px;
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: auto 0; */
    border: 2px solid #ababab;
    color: #000;
}
.flex-direction-col{
    flex-direction:row;
}
.signUp-checkbox{
    color: #116530;
    text-decoration: none;
    font-weight: 600;
}
.signUp-checkbox:hover{
    color: #116530;
    text-decoration: underline;

}
.btn-reg-holder{
    position: relative;
    margin: 0 auto;
    width: 95px;
    z-index: 3;

}
.btn-register{
    padding: 8px 15px;
    /* z-index: 3; */

}
.animated-square{
    position: absolute;
    top: 0;
    border-radius: 0.375rem;
    width: 100%;
    z-index: -2;
    border: 3px solid #BDCB0A;
    height: 100%;
    transform: translate(6%, 12%);
    transition: transform 0.3s ease;
    /* right: 0; */
}
.btn-reg-holder:active .animated-square{
    transform: translate(0%, 0%);
}
.a-user{
    display: flex;
    justify-content: center;
}
.a-user a{
    color: #116530;
    font-weight: 600;
}
.form-check-input:checked{
    background-color: #116530;
}.modal-footer .btn-success {
    background-color: #116530;
    border-color: #116530;
    transition: background-color 0.3s ease;
}