.FTop_Nav{
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.FTop_Nav .topNav_avatar{
    width: 31px;
    border-radius: 50%;
}
.FTop_Nav .back_arrow:hover{
    cursor: pointer;
}
.FTop_Nav .back_arrow{
    color: #fff;
    background: #116530;
    font-size: 25px;
    font-weight: 500;
    height: 31px;
    width: 31px;
    /* width: 50px; */
    padding: 3px;
    border-radius: 50%;
}
.FTop_Nav .greeting_text{
    margin: auto 0;
    align-items: center;
    font-size: 18px;
}
.FTop_Nav .greeting_text .green_text{
    color: #116530;
}