.pagination .page-item.active .page-link {
    background-color: #116530;
    border-color: #116530;
    color: white;
}

.pagination .page-link {
    color: #116530;
}

.pagination .page-link:hover {
    color: #116530;
}