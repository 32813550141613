/* note that the stylesheet and format of this file is used for the other login 
and sin up pages with the left of white, right of green and the curly header  */

/* to remove overflow on left and right */
.row-signInTypePage {
  position: relative;
}
/* setting the half of screen to a green background */
.green-bg {
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #116530;
  z-index: 0;
}
.fffff {
  z-index: 2;
}

.signInTypePage {
  background: #fff;
  position: relative;
}
.signInTypePage-ext {
  height: 100vh;
}
.SignInTypeImgFloatMid {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 15%;
  z-index: 1;
}
.btn-signInType {
  background: #116530;
}
.adj {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
}
.type-section-a-col {
  background-color: #fff;
}

.type-section-a h2 {
  font-weight: 600;
  color: #116530;
}

.type-section-b {
  background: #116530;
  padding-bottom: 50px;
}

@media (max-width: 992px) {
  .adj {
    display: none;
  }

  .type-section-a {
    padding-top: 0;
    margin-top: 0;
  }
  .green-bg {
    display: none;
  }
  .SignInTypeImgFloatMid {
    display: none;
  }
}
@media (min-width: 1664px) {
  .SignInTypeImgFloatMid {
    display: none;
  }
}
@media (max-width: 1399px) {
  .SignInTypeImgFloatMid {
    display: none;
  }
}
